@import "bootstrap/bootstrap";

html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0 0 60px;
}

.welcome {
  max-width: 720px;
  margin: 80px auto 0;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  background-color: #f5f5f5;
  text-align:center ;
}

.footer p {
  padding: 12px 0;
}

a, a:visited {
  text-decoration: none;
}

h1 {
  font-size: 32px;
  margin: 16px 0 0 0;
}
